const SideMenu = ({
  clearChat,
  currentModel,
  setCurrentModel,
  models,
  setTemperature,
  temperature,
}) => (
  <aside className="sidemenu">
    <div className="side-avatar" style={{ width: "225px", height: "225px" }}>
      <img
        src="leya_love.png"
        style={{ width: "100%", height: "100%", borderRadius: "50%" }}
      ></img>
    </div>
    <div className="side-menu-button" onClick={clearChat}>
    click here to reset chat
    </div>
  </aside>
);

const Button = ({ onClick, text }) => (
  <div className="button-picker" onClick={onClick}>
    {text}
  </div>
);

export default SideMenu;

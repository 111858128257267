import { useState } from 'react';
import OpenAISVGLogo from './OpenAISVGLogo';

// Primary Chat Window
const ChatBox = ({ chatLog, setChatInput, handleSubmit, chatInput, chatLogRef, isSubmitting }) =>
  <section className="chatbox">
    <div className="chat-log" ref={chatLogRef}>
      {chatLog.map((message, index) => (
        <ChatMessage key={index} message={message} isFirst={index === 0 && message.role === "assistant"} />
      ))}
    </div>
    <div className="chat-input-holder">
      <form className="form" onSubmit={handleSubmit}>
        <input
          rows="1"
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
          placeholder={"What do you want to say to Leya Love?"}
          className="chat-input-textarea"
        />
        <button className="submit" type="submit" disabled={isSubmitting}>Send</button>
      </form>
    </div>
  </section>

// Individual Chat Message
const ChatMessage = ({ message, isFirst }) => {
  const [vote, setVote] = useState(null);

  const voteHandler = async (voteValue) => {
    const response = await fetch('https://leyalove-server.cantonic.repl.co/vote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: message.id,
        vote: voteValue,
      }),
    });
    const data = await response.json();
    console.log(data);
    setVote(voteValue);
  };

  return (
    <div className={`chat-message ${message.role === "assistant" && "chatgpt"}`}>
      <div className="chat-message-center">
        <div className={`avatar ${message.role === "assistant" ? "bot-avatar" : ""}`}>
          {message.role === "assistant" ? <OpenAISVGLogo /> : <div>You</div>}
        </div>
        <div className="message">
          {message.content}
        </div>
        {message.role === "assistant" && !isFirst && (
          <div className="vote-buttons">
            <button
              style={{ opacity: vote === false ? 0.3 : 1 }}
              onClick={() => voteHandler(true)}
            >
              👍
            </button>
            <button
              style={{ opacity: vote === true ? 0.3 : 1 }}
              onClick={() => voteHandler(false)}
            >
              👎
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatBox;
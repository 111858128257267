import "./normal.css";
import "./App.css";
import { useState, useEffect, useRef } from "react";
import SideMenu from "./SideMenu";
import ChatBox from "./ChatBox";

function App() {
  const [chatInput, setChatInput] = useState("");
  const [temperature, setTemperature] = useState(0.5);
  const [currentModel, setCurrentModel] = useState("gpt-3.5-turbo");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const INITIAL_ASSISTANT_MESSAGE = {
    role: "assistant",
    content: "Hi. I'm Leya Love. What's on your mind?",
  };

  const [chatLog, setChatLog] = useState([INITIAL_ASSISTANT_MESSAGE]);

  // clear chats
  function clearChat() {
    setChatLog([INITIAL_ASSISTANT_MESSAGE]);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    // Check if the input is empty or contains only whitespace
    if (!chatInput.trim()) {
      return;
    }

    // If a submission is already in progress, don't submit again
    if (isSubmitting) return;
    setIsSubmitting(true);

    let chatLogNew = [...chatLog, { role: "user", content: `${chatInput}` }];
    setChatInput("");
    setChatLog(chatLogNew);


    const response = await fetch("https://leya-love-server.replit.app", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        messages: chatLogNew,
        currentModel,
      }),
    });
    const data = await response.json();
    setChatLog([...chatLogNew, { role: "assistant", content: data.message, id: data.id }]);
    setIsSubmitting(false); // Message has been submitted
    var scrollToTheBottomChatLog =
      document.getElementsByClassName("chat-log")[0];
    scrollToTheBottomChatLog.scrollTop = scrollToTheBottomChatLog.scrollHeight;
  }

  const chatLogRef = useRef(null);

  useEffect(() => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
    }
  }, [chatLog]);

  function handleTemp(temp) {
    if (temp > 1) {
      setTemperature(1);
    } else if (temp < 0) {
      setTemperature(0);
    } else {
      setTemperature(temp);
    }
  }

  return (
    <div className="App">
      <SideMenu
        setTemperature={handleTemp}
        temperature={temperature}
        clearChat={clearChat}
      />
      <ChatBox
        chatInput={chatInput}
        chatLog={chatLog}
        setChatInput={setChatInput}
        handleSubmit={handleSubmit}
        chatLogRef={chatLogRef}
        isSubmitting={isSubmitting}
      />
    </div>
  );
}

export default App;
